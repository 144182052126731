/** @format */

import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Base from "./components/layout/Base";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <BrowserRouter>
      <Base>
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path='/privacy-policy' element={<Privacy />} />
        </Routes>
      </Base>
    </BrowserRouter>
  );
}

export default App;
