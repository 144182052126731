/** @format */

import { useState } from "react";
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <header className='bg-black border-b border-gray-700'>
      <div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
        {/* lg+ */}
        <nav className='flex items-center justify-between h-16 lg:h-20'>
          <div className='flex-shrink-0'>
            <a href='#' title='' className='flex items-center'>
              <img className='w-16  lg:w-20' src='assets/logo.png' alt='' />
              <span className='text-lg lg:text-3xl font-bold font-mono  bg-gradient-to-br to-[#8565F7] from-[#BCA8FF] text-transparent bg-clip-text '>
                Tor Wallet
              </span>
            </a>
          </div>
          <button
            type='button'
            className='inline-flex p-2 text-white transition-all duration-200 rounded-md md:hidden focus:bg-gray-800 hover:bg-gray-800'
            onClick={toggleMenu}>
            <svg
              className='w-6 h-6'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d={
                  isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"
                }
              />
            </svg>
          </button>
          <div className='hidden md:flex md:items-center md:space-x-10'>
            <a
              href='https://blocksafu.com/audit/0xBFFc63Cf0e844274a9092620a50a15eAb952EC04'
              target='_blank'
              rel='noreferrer'
              title=''
              className='text-sm font-medium text-white transition-all duration-200 lg:text-base hover:text-opacity-70 focus:text-opacity-70'>
              {" "}
              Audit{" "}
            </a>

            <a
              href='https://chromewebstore.google.com/detail/tor-wallet/gjedgihhdjkmaeadcenenajbccaegfjf'
              title=''
              target='_blank'
              rel='noreferrer'
              className='text-sm font-medium text-white transition-all duration-200 lg:text-base hover:text-opacity-70 focus:text-opacity-70'>
              {" "}
              Download
            </a>
            <a
              href='https://t.me/Tor_Wallet_Official'
              target='_blank'
              rel='noreferrer'
              title=''
              className='text-sm font-medium text-white transition-all duration-200 lg:text-base hover:text-opacity-70 focus:text-opacity-70'>
              {" "}
              Support
            </a>
            <a
              href='https://twitter.com/Tor_Wallet'
              target='_blank'
              rel='noreferrer'
              title=''
              className='text-sm font-medium text-white transition-all duration-200 lg:text-base hover:text-opacity-70 focus:text-opacity-70'>
              {" "}
              News
            </a>
            <a
              href='https://tor-wallet-1.gitbook.io/tor-wallet/'
              target='_blank'
              rel='noreferrer'
              className='bg-gradient-to-br  to-[#8565F7] from-[#BCA8FF] hover:to-[#BCA8FF] hover:from-[#8565F7] transition-all text-[#fff] font-semibold text-lg rounded-full px-5 py-3 flex items-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={20}
                height={20}
                fill='#fff'
                className='bi bi-journal-text mr-3'
                viewBox='0 0 16 16'>
                <path d='M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5' />
                <path d='M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2' />
                <path d='M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z' />
              </svg>
              Documents
            </a>
          </div>
        </nav>
        {/* xs to lg */}
        <nav
          className={`min-h-screen px-4 py-10 text-center bg-black md:hidden ${
            isMenuOpen ? "block" : "hidden"
          } transition-all duration-300 ease-in-out`}>
          <nav className='flex flex-col items-center mt-10 space-y-2'>
            <a
              href='https://blocksafu.com/audit/0xBFFc63Cf0e844274a9092620a50a15eAb952EC04'
              title=''
              target='_blank'
              rel='noreferrer'
              className='py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70'>
              {" "}
              Audit
            </a>
            <a
              href='https://chromewebstore.google.com/detail/tor-wallet/gjedgihhdjkmaeadcenenajbccaegfjf'
              title=''
              target='_blank'
              rel='noreferrer'
              className='py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70'>
              {" "}
              Download{" "}
            </a>
            <a
              href='https://t.me/Tor_Wallet_Official'
              target='_blank'
              rel='noreferrer'
              title=''
              className='py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70'>
              {" "}
              Support
            </a>
            <a
              href='https://twitter.com/Tor_Wallet'
              target='_blank'
              rel='noreferrer'
              title=''
              className='py-2 font-medium text-white transition-all duration-200 focus:text-opacity-70'>
              {" "}
              News
            </a>
            <a
              href='https://tor-wallet-1.gitbook.io/tor-wallet/'
              target='_blank'
              rel='noreferrer'
              className='bg-gradient-to-br  to-[#8565F7] from-[#BCA8FF] hover:to-[#BCA8FF] hover:from-[#8565F7] transition-all text-[#fff] font-semibold text-lg rounded-full px-5 py-3 flex items-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={20}
                height={20}
                fill='#fff'
                className='bi bi-journal-text mr-3'
                viewBox='0 0 16 16'>
                <path d='M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5' />
                <path d='M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2' />
                <path d='M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z' />
              </svg>
              Documents
            </a>
          </nav>
        </nav>
      </div>
    </header>
  );
};

export default Header;
