/** @format */

import React from "react";

const CtaSection = () => {
  return (
    <div className='overflow-hidden bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
          <div className='lg:pr-8 lg:pt-4'>
            <div className='lg:max-w-lg'>
              <h2 className='text-base font-semibold leading-7 text-[#8565F7]'>
                Trading terminal platform.
              </h2>
              <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                Access and trade over 10,000 tokens seamlessly.
              </p>
              {/* <p className='mt-6 text-lg leading-8 text-gray-600'>
    
              </p> */}
              <dl className='mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none'>
                <div className='relative pl-9'>
                  <dt className='inline font-semibold text-gray-900'>
                    <svg
                      className='absolute left-1 top-1 h-5 w-5 text-[#8565F7]'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'>
                      <path
                        fillRule='evenodd'
                        d='M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z'
                        clipRule='evenodd'
                      />
                    </svg>
                    TradingView &nbsp;
                  </dt>
                  <dd className='inline'>
                    Trade what you see with our intuitive interface.
                  </dd>
                </div>
                <div className='relative pl-9'>
                  <dt className='inline font-semibold text-gray-900'>
                    <svg
                      className='absolute left-1 top-1 h-5 w-5 text-[#8565F7]'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'>
                      <path
                        fillRule='evenodd'
                        d='M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z'
                        clipRule='evenodd'
                      />
                    </svg>
                    Market orders &nbsp;
                  </dt>
                  <dd className='inline'>
                    Purchase any token instantly at the optimal price available.
                  </dd>
                </div>
                <div className='relative pl-9'>
                  <dt className='inline font-semibold text-gray-900'>
                    <svg
                      className='absolute left-1 top-1 h-5 w-5 text-[#8565F7]'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'>
                      <path d='M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z' />
                      <path
                        fillRule='evenodd'
                        d='M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z'
                        clipRule='evenodd'
                      />
                    </svg>
                    Limit orders &nbsp;
                  </dt>
                  <dd className='inline'>
                    Buy at your desired time and price without any fees
                    attached.
                  </dd>
                </div>
                <div className='relative pl-9'>
                  <dt className='inline font-semibold text-gray-900'>
                    <svg
                      className='bi bi-gear-wide-connected absolute left-1 top-1 h-5 w-5 text-[#8565F7]'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'>
                      <path d='M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5m0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78zM5.048 3.967l-.087.065zm-.431.355A4.98 4.98 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8zm.344 7.646.087.065z' />
                    </svg>
                    7 chains supported &nbsp;
                  </dt>
                  <dd className='inline'>
                    Trade seamlessly on leading blockchains including Ethereum,
                    Binance Smart Chain, Polygon, Avalanche, Arbitrum, Optimism,
                    and Fantom.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <img
            src='assets/TerminalMockup.png'
            alt='Product screenshot'
            className='w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0'
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
};

export default CtaSection;
