/** @format */

import React from "react";

const Features = () => {
  return (
    <section>
      <div className='py-16 bg-slate-50'>
        <div className='container m-auto px-6 '>
          <div className='lg:flex justify-between items-center mx-auto max-w-7xl px-6 lg:px-8'>
            <div className='lg:w-6/12 lg:p-0 p-7'>
              <h1 className='text-4xl font-bold leading-tight mb-5 capitalize'>
                {" "}
                Circuit Management
              </h1>
              <h2 className='text-lg font-bold leading-7 text-[#8565F7] mb-3'>
                Pool of Tor Circuit{" "}
              </h2>
              <p className='text-lg '>
                {" "}
                We establish and manage a pool of dormant Tor circuits. <br />
                <br />
                Upon necessity, we select a circuit at random and withdraw it
                from the pool. <br />
                <br />
                We eliminate the need to wait for circuit creation by directly
                selecting one from the pool. Simultaneously, the pool seamlessly
                generates additional circuits in the background.
              </p>
            </div>
            <div className='lg:w-5/12 order-2'>
              <img
                src='assets/cir.png'
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-15deg) rotateX(2deg) rotate(2deg)",
                }}
                alt=''
                className='rounded'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='py-16 bg-slate-50'>
        <div className='container m-auto px-6 '>
          <div className='lg:flex justify-between items-center mx-auto max-w-7xl px-6 lg:px-8'>
            <div className='lg:w-5/12 '>
              <img
                src='assets/ip.png'
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(15deg) rotateX(2deg) rotate(-2deg)",
                }}
                alt=''
                className='rounded'
              />
            </div>
            <div className='lg:w-6/12 lg:p-0 p-7 order-2'>
              <h1 className='text-4xl font-bold leading-tight mb-5 capitalize'>
                {" "}
                IP Address Collision
              </h1>

              <p className='text-lg '>
                {" "}
                While utilizing the application, different wallets will employ
                distinct TOR exit nodes, enhancing diversity and
                decentralization in the network. <br />
                <br />
                Typically, we make diligent efforts to ensure that the two sets
                of circuits do not overlap. However, it is important to note
                that there exists a low, albeit possible, probability that a
                wallet may utilize a TOR exit node that has been employed by
                another wallet in the past.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
