/** @format */

import React from "react";

const Section1 = () => {
  return (
    <div className='  py-8 px-16'>
      <div className='mt-16  mx-auto '>
        <div className='grid max-md-grid-cols-1 lg:grid-cols-3  gap-16'>
          <div>
            <h3 className='text-2xl font-extrabold mb-4'>
              What make us
              <span className=' bg-gradient-to-br to-[#8565F7] from-[#BCA8FF] text-transparent bg-clip-text'>
                {" "}
                so different ?
              </span>
            </h3>
            <p className='text-base font-light'>
              Our wallet stands out due to its innovative features, making it
              both unique and highly functional in its capabilities.
            </p>
            <img
              src='assets/dif.png'
              alt='different'
              className='w-full img-fluid '
            />
          </div>
          <div className='text-center'>
            <h3 className='text-2xl font-extrabold mb-4'>
              We never stop{" "}
              <span className=' bg-gradient-to-br to-[#8565F7] from-[#BCA8FF] text-transparent bg-clip-text'>
                {" "}
                building
              </span>
            </h3>
            <p className='text-base font-light'>
              Our goal is to consistently enhance our product to simplify the
              process of decentralization.
            </p>
            <div>
              <a
                href='https://tor-wallet-1.gitbook.io/tor-wallet/roadmap'
                target='_blank'
                rel='noreferrer'>
                <button className='bg-black mt-5 m-auto min-w-64   transition-all text-[#fff] font-semibold text-base rounded-full px-5 py-3 flex items-center justify-center'>
                  Read our roadmap
                </button>
              </a>
              <a
                href='https://tor-wallet-1.gitbook.io/tor-wallet/tokenomics'
                target='_blank'
                rel='noreferrer'>
                <button className='bg-black mt-5 m-auto min-w-64 transition-all text-[#fff] font-semibold text-base rounded-full px-5 py-3 flex items-center justify-center'>
                  Tokenomics of $Tor
                </button>
              </a>
            </div>
          </div>
          <div className='text-end'>
            <h3 className='text-2xl font-extrabold mb-4'>
              Built with the{" "}
              <span className=' bg-gradient-to-br to-[#8565F7] from-[#BCA8FF] text-transparent bg-clip-text'>
                {" "}
                best
              </span>
            </h3>
            <p className='text-base font-light'>
              We meticulously choose our partners to ensure a seamless, quick,
              dependable, and comprehensive user experience.
            </p>
            <>
              {/* ====== Brands Section Start */}
              <section className='bg-white py-8 dark:bg-dark '>
                <div className='container mx-auto'>
                  <div className='flex flex-wrap -mx-4'>
                    <div className='w-full px-4'>
                      <div className='grid grid-cols-2 md:grid-cols-3 gap-4 items-center'>
                        <a href='#!' className='w-28 mx-auto'>
                          <img
                            src='assets/brand_layerzero.png'
                            alt=''
                            className='w-full '
                          />
                        </a>
                        <a href='#!' className='w-28 mx-auto'>
                          <img
                            src='assets/brand_stargate.png'
                            alt=''
                            className='w-full '
                          />
                        </a>
                        <a href='#!' className='w-28 mx-auto'>
                          <img
                            src='assets/brand_walletconnect.png'
                            alt=''
                            className='w-full '
                          />
                        </a>
                        <a href='#!' className='w-28 mx-auto'>
                          <img
                            src='assets/brand_usdv.svg'
                            alt=''
                            className='w-full '
                          />
                        </a>
                        <a href='#!' className='w-28 mx-auto'>
                          <img
                            src='assets/brand_1inch.png'
                            alt=''
                            className='w-full '
                          />
                        </a>
                        <a href='#!' className='w-28 mx-auto'>
                          <img
                            src='assets/brand_alchemy.svg'
                            alt=''
                            className='w-full '
                          />
                        </a>
                        <a href='#!' className='w-28 mx-auto'>
                          <img
                            src='assets/brand_coingecko.png'
                            alt=''
                            className='w-full '
                          />
                        </a>
                        <a href='#!' className='w-28 mx-auto'>
                          <img
                            src='assets/brand_tenderly.png'
                            alt=''
                            className='w-full '
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* ====== Brands Section End */}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
