/** @format */

import React from "react";
import { Timer } from "../utils/Timer";

const Hero = () => {
  return (
    <div className='font-sans text-[#fff] py-12 px-4 bg-black'>
      <div className='max-w-7xl max-md:max-w-md mx-auto max-md:text-center'>
        <div className='grid md:grid-cols-2 gap-12 mt-8'>
          <div>
            <h2 className='lg:text-6xl md:text-5xl text-3xl font-extrabold lg:!leading-[64px] '>
              Tor Wallet
            </h2>
            <h5 className='lg:text-3xl md:text-2xl text-xl mt-3 font-bold'>
              Your{" "}
              <span className=' bg-gradient-to-br to-[#8565F7] from-[#BCA8FF] text-transparent bg-clip-text '>
                all-in-one
              </span>{" "}
              tor wallet
            </h5>
            <p className='text-lg leading-relaxed mt-5'>
              First EVM compatible privacy focused wallet with built-in TOR.
              Earn points on every transactions you made.
            </p>
            <div className='mt-12 flex gap-3 flex-wrap'>
              <a
                href='https://chromewebstore.google.com/detail/tor-wallet/gjedgihhdjkmaeadcenenajbccaegfjf'
                target='_blank'
                rel='noopener noreferrer'>
                <button
                  className='bg-gradient-to-br to-[#8565F7] from-[#BCA8FF]
                hover:to-[#BCA8FF] hover:from-[#8565F7] transition-all
                text-[#fff] font-semibold text-lg rounded-full px-3 py-3 flex
                items-center'>
                  <img
                    src='assets/chrome.png'
                    className='w-8 mr-3 fa-spin'
                    alt=''
                  />
                  Download & Install on Chrome
                </button>
              </a>
              <a
                href='https://wallet.torwallet.xyz/'
                target='_blank'
                rel='noopener noreferrer'>
                <button
                  type='button'
                  className='bg-gradient-to-br  to-[#8565F7] from-[#BCA8FF] hover:to-[#BCA8FF] hover:from-[#8565F7] transition-all text-[#fff] font-semibold text-lg rounded-full px-5 py-3 flex items-center'>
                  <img
                    src='assets/round.png'
                    className='w-8 mr-3 fa-spin'
                    alt=''
                  />
                  Try it now
                </button>
              </a>
            </div>
            <div className='mt-12 grid grid-cols-2 md:grid-cols-4 gap-4 items-center place-content-center'>
              <img
                src='assets/Google_Chrome.png'
                className='w-28 mx-auto'
                alt='google-logo'
              />
              <img
                src='assets/Firefox.png'
                className='w-28 mx-auto'
                alt='facebook-logo'
              />
              <img
                src='assets/brave.png'
                className='w-28 mx-auto'
                alt='linkedin-logo'
              />
              <img
                src='assets/Safari.png'
                className='w-14 mx-auto'
                alt='pinterest-logo'
              />
            </div>
            <ul className='flex items-center justify-start mt-8 space-x-4 flex-wrap'>
              <li className='bg-[#343538] h-10 w-10 rounded-full flex items-center justify-center shrink-0'>
                <a
                  href='https://twitter.com/tor_wallet'
                  target='_blank'
                  rel='noreferrer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='#fff'
                    className='bi bi-twitter-x'
                    viewBox='0 0 16 16'>
                    <path d='M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z' />
                  </svg>
                </a>
              </li>
              <li className='bg-[#343538] h-10 w-10 rounded-full flex items-center justify-center shrink-0'>
                <a
                  href='https://t.me/Tor_Wallet_Official'
                  target='_blank'
                  rel='noreferrer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='#fff'
                    className='bi bi-telegram'
                    viewBox='0 0 16 16'>
                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09' />
                  </svg>
                </a>
              </li>

              <li className='bg-[#343538] h-10 w-10 rounded-full flex items-center justify-center shrink-0'>
                <a
                  href='https://medium.com/@tor_wallet'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='#fff'
                    className='bi bi-medium'
                    viewBox='0 0 16 16'>
                    <path d='M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8m4.95 0c0 2.34-1.01 4.236-2.256 4.236S9.463 10.339 9.463 8c0-2.34 1.01-4.236 2.256-4.236S13.975 5.661 13.975 8M16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795' />
                  </svg>
                </a>
              </li>

              <li className='bg-[#343538] h-10 w-10 rounded-full flex items-center justify-center shrink-0'>
                <a
                  href='https://www.instagram.com/torwallet/'
                  target='_blank'
                  rel='noreferrer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='#fff'
                    className='bi bi-instagram'
                    viewBox='0 0 16 16'>
                    <path d='M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334' />
                  </svg>
                </a>
              </li>

              <li className='bg-[#343538] h-10 w-10 rounded-full flex items-center justify-center shrink-0'>
                <a
                  href='https://github.com/torwalletxyz'
                  target='_blank'
                  rel='noreferrer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='#fff'
                    className='bi bi-github'
                    viewBox='0 0 16 16'>
                    <path d='M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8' />
                  </svg>
                </a>
              </li>
              <li className='bg-[#343538] h-10 w-10 rounded-full flex items-center justify-center shrink-0'>
                <a
                  href='https://www.reddit.com/r/tor_wallet/'
                  target='_blank'
                  rel='noreferrer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='#fff'
                    className='bi bi-reddit'
                    viewBox='0 0 16 16'>
                    <path d='M6.167 8a.83.83 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661m1.843 3.647c.315 0 1.403-.038 1.976-.611a.23.23 0 0 0 0-.306.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0 .213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83s.83-.381.83-.83a.831.831 0 0 0-1.66 0z' />
                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.828-1.165c-.315 0-.602.124-.812.325-.801-.573-1.9-.945-3.121-.993l.534-2.501 1.738.372a.83.83 0 1 0 .83-.869.83.83 0 0 0-.744.468l-1.938-.41a.2.2 0 0 0-.153.028.2.2 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224q-.03.17-.029.353c0 1.795 2.091 3.256 4.669 3.256s4.668-1.451 4.668-3.256c0-.114-.01-.238-.029-.353.401-.181.688-.592.688-1.069 0-.65-.525-1.165-1.165-1.165' />
                  </svg>
                </a>
              </li>

              <li className='bg-[#343538] h-10 w-10 rounded-full flex items-center justify-center shrink-0'>
                <a
                  href='https://www.youtube.com/@TorWallet/videos'
                  target='_blank'
                  rel='noreferrer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    fill='#fff'
                    className='bi bi-youtube'
                    viewBox='0 0 16 16'>
                    <path d='M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z' />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div className=' relative'>
            <div className='flex gap-3 justify-center items-center flex-wrap absolute left-1/2  -top-12   transform -translate-x-1/2 -translate-y-1/2 mt-4'>
              <a
                href='https://pancakeswap.finance/swap?outputCurrency=0xBFFc63Cf0e844274a9092620a50a15eAb952EC04'
                target='_blank'
                title='PCS'
                rel='noopener noreferrer'>
                <img src='assets/cake.svg' className='w-16' alt='' />
              </a>

              <a
                href='https://www.dextools.io/app/en/bnb/pair-explorer/0x317ffc8ae6ba7cd5d2bd76fbbf6cf9b5e54b5efa'
                target='_blank'
                title='dextools'
                className='bg-white rounded-full w-16 h-16 flex justify-center items-center'
                rel='noopener noreferrer'>
                <img src='assets/dextools.svg' className='w-12' alt='' />
              </a>
            </div>
            {/* <Timer /> */}
            <a
            href='https://pancakeswap.finance/swap?outputCurrency=0xBFFc63Cf0e844274a9092620a50a15eAb952EC04'
            target='_blank'
            rel='noopener noreferrer'
            className='text-[#BCA8FF]  text-xl mb-0 text-center flex gap-2 items-center justify-center scc'>
            We are Live on PCS
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='currentColor'
              className='bi bi-box-arrow-up-right'
              viewBox='0 0 16 16'>
              <path
                fillRule='evenodd'
                d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5'
              />
              <path
                fillRule='evenodd'
                d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z'
              />
            </svg>
          </a>
            <div className='flex flex-wrap md:flex-nowrap mt-4'>
              <img
                src='assets/r_secondView1.png'
                className='shrink-0 w-full md:w-56  rounded-md object-contain hidden md:block opacity-70 mr-0 md:-mr-12'
                alt=''
              />
              <img
                src='assets/r_mainView1.png'
                className=' w-full md:w-72 rounded-md object-contain relative  md:block z-20'
                alt=''
              />
              <img
                src='assets/r_thirdView1.png'
                className='shrink-0 w-full md:w-56  rounded-md object-contain hidden md:block opacity-70 ml-0 md:-ml-12'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
