/** @format */

import React from "react";
import Hero from "../components/elements/Hero";
import CtaSection from "../components/elements/CtaSection";
import CardSection from "../components/elements/CardSection";
import Section1 from "../components/elements/Section1";
import Faqs from "../components/elements/Faqs";
import Features from "../components/elements/Features";

const Home = () => {
  return (
    <React.Fragment>
      <Hero />
      <Section1 />
      <CtaSection />
      <Features />
      {/* <Faqs /> */}
      <CardSection />
    </React.Fragment>
  );
};

export default Home;
