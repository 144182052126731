/** @format */

import React from "react";

const CardSection = () => {
  return (
    <div className=' text-[#fff] bg-[#27282b]  py-8 px-16'>
      <div className='mt-16  mx-auto '>
        <div className='grid max-md-grid-cols-1 lg:grid-cols-2  gap-16'>
          <div>
            <h3 className='text-2xl font-extrabold mb-4 bg-gradient-to-br to-[#8565F7] from-[#BCA8FF] text-transparent bg-clip-text'>
              Compatible with Ledger Wallet
            </h3>
            <p className='text-lg'>
              Ensuring your security is paramount to us. That's why we
              prioritize security measures such as requesting your password for
              on-the-fly wallet decryption, never storing your private key in
              plain text, and integrating the Ledger Wallet, renowned as the top
              cold wallet globally.
            </p>
            <a
              href='https://wallet.torwallet.xyz/'
              target='_blank'
              rel='noopener noreferrer'>
              <button
                type='button'
                className='bg-gradient-to-br mt-5 to-[#8565F7] from-[#BCA8FF] hover:to-[#BCA8FF] hover:from-[#8565F7] transition-all text-[#fff] font-semibold text-lg rounded-full px-5 py-3 flex items-center'>
                <img
                  src='assets/round.png'
                  className='w-8 mr-3 fa-spin'
                  alt=''
                />
                Try it now
              </button>
            </a>
          </div>
          <div className='max-md:order-1'>
            <h3 className='text-2xl font-extrabold mb-4 bg-gradient-to-br to-[#8565F7] from-[#BCA8FF] text-transparent bg-clip-text'>
              Virtual & Physical debit card
            </h3>
            <p className='text-lg'>
              We are actively developing the capability for users to acquire a
              physical debit card connected to their cryptocurrency wallet.
              Anticipated to be available in the first half of 2024, if you wish
              to stay updated on its release, please let us know.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSection;
